import FilerobotImageEditor, {TABS, TOOLS} from "react-filerobot-image-editor";
import {useEffect, useState} from "react";


export const Editor = () => {
    const [isImgEditorShown, setIsImgEditorShown] = useState(false);

    const handleSave = (imageData) => {
        imageData.imageCanvas.toBlob((blob) => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = imageData.fullName;
            a.click();
            URL.revokeObjectURL(url);
        });
    };

    useEffect(() => {
        setIsImgEditorShown(true)
    }, []);

    const img = new Image()
    img.crossOrigin = "anonymous"
    img.src = "https://cdn2.wadiz.kr/2024/04/25/4b53eaad-f475-4773-9b97-829c21cb35a2.jpg"

    useEffect(() => {
        document.querySelector(".FIE_tabs")?.append()
    }, [isImgEditorShown])

    return (
        <>
            {
                isImgEditorShown && (
                    <FilerobotImageEditor
                        source={img}
                        onSave={(editedImageObject, designState) => {
                            console.log("saved", editedImageObject)
                            handleSave(editedImageObject)
                        }}
                        noCrossOrigin={true}
                        annotationsCommon={{
                            fill: '#000000'
                        }}
                        Text={{ text: '텍스트를 입력하세요...' }}
                        Rotate={{ angle: 90, componentType: 'slider' }}
                        tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.FILTERS, TABS.RESIZE, TABS.FINETUNE]}
                        defaultTabId={TABS.ANNOTATE}
                        defaultToolId={TOOLS.TEXT}
                    />
                )
            }
        </>
    )
}