import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEquals,
  faWonSign,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useMemo, useState } from "react";
import { MESSAGE_TARGET_FILTER } from "../../constants/menus";
import GrayButton from "../GrayButton";
import moment from "moment";
import { getMessagePrice, range } from "../../libs/helper";
import {cloneDeep, floor} from "lodash";
import SuspensePopup from "../SuspensePopup";
import {Button, CalendarBar, RadioGroup} from "omoplay";
import {getExpectedCustomerCount} from "../../api/message/volatility-message.api";
import InputGroup from "../form/InputGroup";
import TargetMessageForm from "./TargetMessageForm";

export const MessageTargetSetting = ({id, targetType, targetFilters, reservedDate, onChangeTargetType,
                                       onChangeTargetFilters, messageData, onChangeCustomerCnt,
                                       onChangeReservedDate, onChangeAdult, onChangeSelectUserIds}) => {
  const [onActiveFilter, setActiveFilter] = useState([null, null, null]);
  const [testMessagePopup, setTestMessagePopup] = useState(false)
  const [customerCount, setCustomerCount] = useState(0)
  const [filterTargetType, setFilterTargetType] = useState(targetType ?? "total")

  const type = messageData.type

  const convertFilter = (filters) => {
    return filters && cloneDeep(filters).map((filter) => {
      if (filter.category === "address") {
        filter.value = filter.label;
      } else if (filter.category === "createdWeek") {
        filter.value = filter.value.split("_")[1];
      }
      return {
        category: filter.category,
        value: filter.value,
        label: filter.label,
      };
    });
  }

  useEffect(() => {
    getExpectedCustomerCount(id, filterTargetType, convertFilter(targetFilters)).then(({data}) => {
      setCustomerCount(data)
      onChangeCustomerCnt(data)
    })
  }, [targetFilters])

  const handleChangeFilter = (depth, value, isActive) => {
    if (isActive) {
      cancelItem(value, depth);
    } else {
      const updateValue = [...onActiveFilter];
      updateValue[depth - 1] = value;
      setActiveFilter(updateValue);
      if (depth > 1) {
        MESSAGE_TARGET_FILTER.filter(
          (parent) => parent.value === updateValue[0]
        ).forEach((parent) => {
          parent.data
            .filter((item) => item.value === updateValue[1])
            .forEach((item) => {
              if (item.data && item.data.length > 0) {
                item.data
                  .filter((children) => children.value === updateValue[2])
                  .forEach((children) => {
                    addItem(children, item, parent);
                  });
              } else {
                addItem(item, parent);
              }
            });
        });
      }
    }
  };


  const cancelItem = (value, depth = null) => {
    const updateValue = [...targetFilters];
    const removeIndex = updateValue.findIndex((item) => item.value === value);
    if (removeIndex > -1) {
      updateValue.splice(removeIndex, 1);
      onChangeTargetFilters(convertFilter(updateValue));
    }

    if (depth != null && onActiveFilter[depth - 1] === value) {
      const updateValue = [...onActiveFilter];
      updateValue[depth - 1] = null;
      setActiveFilter(updateValue);
    }
  };

  const addItem = (children, item, parent = null) => {
    const updateValue = [...targetFilters];
    const existIndex = updateValue.findIndex(
      (item) => item.value === children.value
    );
    if (existIndex > -1) return;

    updateValue.push({
      category: parent ? parent.value : item.value,
      value: children.value,
      label: parent ? item.label + " " + children.label : children.label,
    });
    onChangeTargetFilters(convertFilter(updateValue));
  };

  const filterCategories = useMemo(() => {
    if (targetFilters) {
      return Array.from(new Set(targetFilters.map((item) => item.category)));
    }
  }, [targetFilters]);

  const handleCategoryClick = (value) => {
    const updateValue = [...onActiveFilter];
    updateValue[0] = value;
    setActiveFilter(updateValue);
  };

  const handleReserveDateChange = (type, value) => {
    const updateValue = { ...reservedDate }
    if (type === "date") {
      value.startDate = moment(value).format("YYYY-MM-DD")
      value.endDate = moment(value).format("YYYY-MM-DD")
    }
    updateValue[type] = value;
    onChangeReservedDate(updateValue)
  }


  return (
    <div className="border rounded-lg bg-white w-[1032px] p-8 mb-20">
      <div className="flex-col">
        <div className="flex justify-between">
          <div>
            <label className="text-lg font-semibold text-gray-900 relative">
              대상 조건 설정
              <span className="absolute text-red-500 -end-2 -top-2">*</span>
            </label>
            <p className="text-gray-500 text-sm mb-2">
              메시지 발송 대상의 조건을 설정해 주세요.
            </p>
          </div>
          <div>
            <Button variants="default" value="테스트 발송" onClick={() => setTestMessagePopup(true)} />
          </div>
        </div>
        <div className="flex border-gray-200 border rounded-lg flex-col">
          <div className="flex-col">
            <div className="flex items-center mb-1 p-8 mt-4 pb-2">
              <label className="text-md font-medium text-gray-900 relative">
                누구에게 발송하시겠습니까?
              </label>
            </div>
            <div className="flex gap-4 p-8 pt-2 text-sm">
              <div
                className={
                  "flex border rounded justify-center items-center w-[290px] h-[96px] flex-col cursor-pointer " +
                  (filterTargetType === "total"
                    ? "border-[#4046E3] bg-indigo-50 text-indigo-600 "
                    : "border-gray-100 bg-gray-100 text-gray-600 ")
                }
                onClick={() => {
                  targetFilters = []
                  onChangeTargetFilters([])
                  setFilterTargetType("total")
                  setActiveFilter([])
                  onChangeSelectUserIds(null)
                  onChangeTargetType("total")
                }}
              >
                <p className="font-semibold">전체 고객</p>
              </div>
              <div
                className={
                  "flex border rounded justify-center items-center w-[290px] h-[96px] flex-col cursor-pointer " +
                  (filterTargetType === "filter"
                    ? "border-[#4046E3] bg-indigo-50 text-indigo-600 "
                    : "border-gray-100 bg-gray-100 text-gray-600 ")
                }
                onClick={() => {
                  setFilterTargetType("filter")
                  onChangeTargetType("filter")
                  onChangeSelectUserIds(null)
                }}
              >
                <p className="font-semibold">조건 필터링 고객</p>
              </div>
              <div
                  className={
                      "flex border rounded justify-center items-center w-[290px] h-[96px] flex-col cursor-pointer " +
                      (filterTargetType === "target"
                          ? "border-[#4046E3] bg-indigo-50 text-indigo-600 "
                          : "border-gray-100 bg-gray-100 text-gray-600 ")
                  }
                  onClick={() => {
                    setCustomerCount(0)
                    setFilterTargetType("target")
                    onChangeTargetType("target")
                  }}
              >
                <p className="font-semibold">대상 고객</p>
              </div>
            </div>
            {filterTargetType === "filter" && (
              <div className="p-8 pt-0">
                <button className="font-semibold text-sm">
                  + 조건 필터 추가하기
                </button>
                <div className="border-gray-200 bg-gray-100 border">
                  <div className="flex divide-x border-b border-gray-200">
                    <div className="w-1/3 h-full">
                      <ul className="flex-col gap-2 flex p-4 overflow-auto max-h-[298px]">
                        {MESSAGE_TARGET_FILTER.map((item, index) => {
                          let disabled = item.value === "" ? "disabled" : ""
                          const isActive =
                            onActiveFilter[0] === item.value ||
                            targetFilters.findIndex(
                              (target) => target.value === item.value
                            ) > -1;
                          return (
                            <li
                              key={"filter_depth_1_" + index}
                              className={
                                `rounded w-full py-3 px-4 border text-sm cursor-pointer ${disabled}` +
                                (isActive
                                  ? "bg-indigo-50 border-indigo-600 text-indigo-600 font-semibold "
                                  : "bg-white border-white text-gray-600")
                              }
                              onClick={() => {
                                if (!disabled) {
                                  handleChangeFilter(1, item.value, isActive)
                                }
                              }
                            }
                            >
                              {item.label}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="w-1/3 h-full">
                      <ul className="flex-col gap-2 flex p-4 overflow-auto max-h-[298px]">
                        {MESSAGE_TARGET_FILTER.filter(
                          (item) => item.value === onActiveFilter[0]
                        ).map((item) =>
                          item.data.map((item, index) => {
                            const isActive =
                              onActiveFilter[1] === item.value ||
                              targetFilters.findIndex(
                                (target) => target.value === item.value
                              ) > -1;
                            return (
                              <li
                                key={"filter_depth_2_" + index}
                                className={
                                  "rounded w-full py-3 px-4 border text-sm cursor-pointer " +
                                  (isActive
                                    ? "bg-indigo-50 border-indigo-600 text-indigo-600 font-semibold "
                                    : "bg-white border-white text-gray-600")
                                }
                                onClick={() =>
                                  handleChangeFilter(2, item.value, isActive)
                                }
                              >
                                {item.label}
                              </li>
                            );
                          })
                        )}
                      </ul>
                    </div>
                    <div className="w-1/3 h-full">
                      <ul className="flex-col gap-2 flex p-4 overflow-auto max-h-[298px]">
                        {MESSAGE_TARGET_FILTER.filter(
                          (item) => item.value === onActiveFilter[0]
                        ).map((item) =>
                          item.data
                            .filter((item) => item.value === onActiveFilter[1])
                            .map((item) =>
                              item.data?.map((item, index) => {
                                const isActive =
                                  onActiveFilter[2] === item.value ||
                                  targetFilters.findIndex(
                                    (target) => target.value === item.value
                                  ) > -1;
                                return (
                                  <li
                                    key={"filter_depth_3_" + index}
                                    className={
                                      "rounded w-full py-3 px-4 border text-sm cursor-pointer " +
                                      (isActive
                                        ? "bg-indigo-50 border-indigo-600 text-indigo-600 font-semibold "
                                        : "bg-white border-white text-gray-600")
                                    }
                                    onClick={() =>
                                      handleChangeFilter(
                                        3,
                                        item.value,
                                        isActive
                                      )
                                    }
                                  >
                                    {item.label}
                                  </li>
                                );
                              })
                            )
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="flex-col p-4 gap-4">
                    {filterCategories &&
                      filterCategories.map((value) => {
                        const categoryIndex = MESSAGE_TARGET_FILTER.findIndex(
                          (parent) => parent.value === value
                        );
                        return (
                          <div
                            className="flex text-sm"
                            key={"setting_" + value}
                          >
                            <label
                              className="min-w-[120px] underline mt-3 ml-2 font-semibold cursor-pointer"
                              onClick={() =>
                                handleCategoryClick(
                                  MESSAGE_TARGET_FILTER[categoryIndex].value
                                )
                              }
                            >
                              {MESSAGE_TARGET_FILTER[categoryIndex].label}
                            </label>
                            <ul className="flex gap-4 flex-wrap">
                              {targetFilters
                                .filter((item) => item.category === value)
                                .map((item, index) => {
                                  return (
                                    <li
                                      key={categoryIndex + "_item_" + index}
                                      className="bg-white rounded p-2 min-w-[130px] text-sm flex justify-between items-center"
                                    >
                                      <p className="ml-2 mr-4">{item.label}</p>
                                      <div>
                                        <GrayButton
                                          className="px-1.5 w-4 h-6"
                                          onClick={() => cancelItem(item.value)}
                                        >
                                          <FontAwesomeIcon icon={faXmark} />
                                        </GrayButton>
                                      </div>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
            {
                filterTargetType === "target" && (
                  <TargetMessageForm id={id} messageData={messageData} onSelectedUserIds={(userIds) => {
                    onChangeSelectUserIds(userIds)
                    setCustomerCount(userIds.length)
                  }} />
                )
            }
            <div className="bg-gray-100 p-4 flex-col">
              <div className="flex items-center mb-1 p-8 pb-2">
                <label className="text-md font-medium text-gray-900 relative">
                  총 예상 발송 비용
                </label>
              </div>
              <div className="flex items-center gap-4">
                <div className="rounded border border-gray-300 text-center w-[286px]">
                  <div className="text-gray-400 py-1.5 text-sm border-b border-gray-300">
                    예상 고객 수 (발송 건 수)
                  </div>
                  <div className="bg-white py-2 font-semibold text-center">
                    {customerCount.toLocaleString()}명 (건)
                  </div>
                </div>
                <div className="w-6 h-6 bg-white rounded flex justify-center items-center text-gray-500">
                  <FontAwesomeIcon icon={faXmark} />
                </div>
                <div className="rounded border border-gray-300 text-center w-[118px]">
                  <div className="text-gray-400 py-1.5 text-sm border-b border-gray-300">
                    메시지 단가
                  </div>
                  <div className="py-2 font-semibold">
                    {getMessagePrice(type)}원
                  </div>
                </div>
                <div className="w-6 h-6 bg-white rounded flex justify-center items-center text-gray-500">
                  <FontAwesomeIcon icon={faEquals} />
                </div>
                <div className="rounded border border-gray-300 text-center w-[326px]">
                  <div className="text-gray-400 py-1.5 text-sm border-b border-gray-300">
                    총 예상 발송 비용
                  </div>
                  <div className="bg-white py-2 font-semibold flex justify-center gap-2 text-indigo-600">
                    <>
                      <div className="bg-indigo-100 w-6 h-6 flex justify-center items-center  rounded">
                        <FontAwesomeIcon icon={faWonSign} size={"xs"} />
                      </div>
                      {floor(
                          customerCount *
                          getMessagePrice(type)
                      ).toLocaleString()}
                      원
                    </>
                  </div>
                </div>
              </div>
              <p className="text-gray-500 text-sm my-4 text-[12px]">
                * ‘예상 고객 수’는 발송을 시도하는 고객 수를 의미하고, 실제 발송되는 고객 수와는 차이가 있을 수 있습니다.
              </p>
              <p className="text-gray-500 text-sm my-4 text-[12px]">
                * 메시지 발송 등록 시 과금된 비용과 실발송 후의 차액은 발생할 수 있으며, 발송되지 않은 발송 건은 청구되지 않습니다.
              </p>
            </div>
          </div>
        </div>
      </div>
      <InputGroup
          label={"연령 인증 메시지"}
          isRequired={true}
          className="py-4"
      >
        <div className="flex-align-center gap-2">
          <RadioGroup
              items={[
                {
                  label: "선택안함",
                  value: "N"
                },
                {
                  label: "선택함",
                  value: "Y"
                }
              ]}
              initChecked={messageData.adult ?? "N"}
              value={messageData.adult}
              onSelected={(value) => {
                onChangeAdult(value)
              }}
          />
        </div>
        <div>
          <div className="font-12px">연령인증 전까지 채팅방 메시지에 커버가 씌워지며, 20세 이상 성인만 내용을 확인할 수 있습니다.</div>
          <div className="font-12px">20세 이상에게 적합한 내용을 담고 있다면 필수 선택해주세요.</div>
          <div className="font-12px">예: 주류, 청소년 이용불가 게임 및 영화 등</div>
        </div>
      </InputGroup>
      <div className="mt-12">
        <label className="text-lg font-semibold text-gray-900 relative">
          발송 날짜/시간 설정
        </label>
        <div className="flex gap-2 my-2 items-center">
          <div className="flex border-gray-200 border rounded">
            <CalendarBar minDate={new Date()} multiple={false} initDate={{
              startDate: moment(reservedDate.date.startDate).toDate(),
              endDate: moment(reservedDate.date.endDate).toDate()
            }} onConfirm={(dateProps) => {
              handleReserveDateChange("date", dateProps.startDate);
            }} />
          </div>
          <select
              className="w-100 bg-white border border-gray-200 text-gray-900 rounded focus:ring-indigo-500 focus:border-indigo-500 block p-2 h-[45px]"
              value={reservedDate.hour}
              onChange={(e) =>
                  handleReserveDateChange("hour", e.target.value)
              }
          >
            {[...range(13, 8)].map((time) => {
              return (
                  <option value={String(time).padStart(2, "0")}>
                    {String(time).padStart(2, "0")}시
                  </option>
              );
            })}
          </select>
          <select
              className="w-100 bg-white border border-gray-200 text-gray-900 rounded focus:ring-indigo-500 focus:border-indigo-500 block p-2 h-[45px]"
              value={reservedDate.min}
              onChange={(e) => {
                handleReserveDateChange("min", e.target.value)
              }}
          >
            {[...Array(60).keys()].map((time) => {
              if (reservedDate.hour === "20" && time > 55) {
                return
              }
              return (
                  <option value={String(time).padStart(2, "0")}>
                    {String(time).padStart(2, "0")}분
                  </option>
              );
            })}
          </select>
        </div>
        <div className="bg-gray-100 w-full rounded px-4 py-0.5">
          <p className="text-gray-500 text-[12px] my-2">
            * 한국 사용자는{" "}
            <span className="text-red-600">
              20:55부터 08:00까지는 메시지 수신이 불가합니다.{" "}
            </span>{" "}
            해외 사용자에게는 시간 제한 없이 보내지니 참고해 주세요.
          </p>
        </div>
      </div>
      {testMessagePopup && (
          <SuspensePopup
              isEditPopup={true}
              title={"테스트 발송하기"}
              size={"w-[1000px]"}
              onClose={() => setTestMessagePopup(false)}
          >
            <TargetMessageForm messageData={messageData}
                               isTest={true}
                               onSelectedUserIds={() => {}}
                               onSendMessage={() => setTestMessagePopup(false)}
            />
          </SuspensePopup>
      )}
    </div>
  );
};

export default MessageTargetSetting;
