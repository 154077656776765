import InputGroup from "../form/InputGroup";
import {TabBar} from "omoplay";
import {useEffect, useState} from "react";
import MessageSection from "./MessageSection";

export const MessageVariantsSetting = ({id, onChangeVariants}) => {
    const [tab, setTab] = useState("age")
    const [variants, setVariants] = useState([])

    useEffect(() => {
        onChangeVariants("messageDataVariants", variants)
    }, [variants]);

    return (
        <div className="border rounded-lg bg-white w-[1032px] p-8 mb-20">
          <InputGroup
              label={"AI 맞춤 메시지"}
              className="py-4"
          >
            <div>
              <div>
                나이대에 따라 메시지의 어투를 다르게 발송할 수 있습니다.<br/>
                자동 생성된 나이대별 어투 메시지는 반드시 내용을 확인한 후에 반영 여부를 결정해 주세요.
              </div>
              <div className="font-12px grey-7-color">
                * 나이대별 어투 사용을 선택하지 않은 나이대는 ‘기본 메시지’로 발송됩니다.<br/>
                * 나이대별 메시지는 1회 생성되며, 기본 메시지가 변경된 경우 나이대별 메시지는 재생성이 필요합니다.<br/>
                * 메시지 발송비용에는 차이가 없습니다.
              </div>
            </div>
          </InputGroup>
          <TabBar items={[{label: "나이대별 어투", value: "age", selected: true}, {label: "개인별 맞춤 피드 순서", value: "order"}]}
                  onChanged={() => {
                  }}
          />
          <div className="flex-col gap-4">
              <MessageSection id={id} title="기본 메시지" variantsKey="default" onChangeVariants={(item) => {

                  setVariants((prev) => {
                      prev = prev.filter(item => item.key !== "default")
                      prev.push(item)
                      return [...prev]
                  })
              }} />
              <MessageSection id={id} title="20~29세" variantsKey="20대" onChangeVariants={(item) => {

                  setVariants((prev) => {
                      prev = prev.filter(item => item.key !== "20대")
                      prev.push(item)
                      return [...prev]
                  })
              }} />
              <MessageSection id={id} title="30~39세" variantsKey="30대" onChangeVariants={(item) => {

                  setVariants((prev) => {
                      prev = prev.filter(item => item.key !== "30대")
                      prev.push(item)
                      return [...prev]
                  })
              }} />
              <MessageSection id={id} title="40~49세" variantsKey="40대" onChangeVariants={(item) => {

                  setVariants((prev) => {
                      prev = prev.filter(item => item.key !== "40대")
                      prev.push(item)
                      return [...prev]
                  })
              }} />
              <MessageSection id={id} title="50~59세" variantsKey="50대" onChangeVariants={(item) => {

                  setVariants((prev) => {
                      prev = prev.filter(item => item.key !== "50대")
                      prev.push(item)
                      return [...prev]
                  })
              }} />
              <MessageSection id={id} title="60~69세" variantsKey="60대" onChangeVariants={(item) => {

                  setVariants((prev) => {
                      prev = prev.filter(item => item.key !== "60대")
                      prev.push(item)
                      return [...prev]
                  })
              }} />
          </div>
        </div>
    );
};

export default MessageVariantsSetting;
