import Layout from "../components/layout/Layout";
import VolatilityMessageList from "../components/page/VolatilityMessageList";

const VolatilityMessageListPage = () => {
  return (
    <Layout>
      <VolatilityMessageList />
    </Layout>
  );
};
export default VolatilityMessageListPage;
