import {useSearchParams} from "react-router-dom";

const PaymentFail = () => {
  const [query] = useSearchParams();
  alert(query.get("message"))
  window.location.href = "/payments"

  return (<></>)
};
export default PaymentFail;
