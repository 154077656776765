import {getProductDetail} from "../../api/ai/ai.api";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {Editor} from "../form/Editor";

const ProductDetail = () => {
    const [query] = useSearchParams()
    const [info, setInfo] = useState({ images: [], options: [], others: {} })

    useEffect(() => {
    }, []);


    useEffect(() => {
        const id = query.get("id")
        getProductDetail(id).then(({data}) => {
            setInfo(data.info)
        })
    }, [query]);

    return (
        <>
            <div>
                <div className="flex gap-4">
                    <div className="flex-1">
                        <img src={info.images[0]} style={{width: "500px", height: "500px"}} />
                    </div>
                    <div className="flex-1 flex-col gap-4">
                        <div className="font-bold" style={{fontSize: "30px"}}>{info.productName}</div>
                        <div className="flex gap-2">
                            {
                                info.discountRate && (
                                    <div className="font-bold font-20px text-red-500">{info.discountRate}</div>
                                )
                            }
                            {
                                info.price && (
                                    <div className="font-bold font-20px">{info.price.toLocaleString()}원</div>
                                )
                            }
                            {
                                info.originalPrice && (
                                    <div className={`font-bold font-20px ${info.price ? 'line-through grey-7-color' : ''}`}>{info.originalPrice.toLocaleString()}원</div>
                                )
                            }
                        </div>
                        <div className="font-semibold font-16px">{JSON.stringify(info.category)}</div>
                        <div className="font-semibold font-16px">배송비: {JSON.stringify(info.deliveryFee)}</div>
                        <div className="w-[400px] font-semibold font-16px grey-7-color">옵션: {
                            info.options?.map(option => {
                                return (<div>{JSON.stringify(option)}</div>)
                            })
                        }</div>
                        <div className="w-[400px] font-semibold font-16px grey-7-color whitespace-pre-line">{info.summary}</div>
                    </div>
                </div>
            </div>
            <div className="bg-white p-8">
                <div className="flex-col flex-align-center">
                    {
                        info.images?.map(url => {
                            return (
                                <div className="p-8">
                                    <img src={url} style={{width: "auto"}} />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    );
};

export default ProductDetail;
