
import { useEffect, useState } from "react";
import SearchInput from "../SearchInput";
import {CalendarBar, Checkbox, Pagination, Table} from "omoplay";
import {getAllDateRange} from "../../libs/helper";
import {getOrdersStatistics} from "../../api/statistics/statistics.api";
const moment = require("moment");

const OnlineOrderUsers = () => {
  const [tableUsers, setTableUsers] = useState([])
  const [search, setSearch] = useState("")
  const [totalCount, setTotalCount] = useState(0)
  const [totalSales, setTotalSales] = useState(0)
  const [page, setPage] = useState(1);
  const [dateRange, setDateRange] = useState(getAllDateRange())
  const [isOffline, setOffline] = useState(false)

  const limit = 10

  useEffect(() => {
    getOrdersStatistics(null, dateRange.startDate, dateRange.endDate, search, isOffline, page, limit)
        .then(({data}) => {
          setTotalCount(data.totalCount)
          setTotalSales(data.totalSales)
          setTableUsers(data.users.map(user => {
            return [
              <div className="">{user.productNames.map(name => <div>{name}</div>)}</div>,
              <div className={`flex-center-all h-full break-all ${user.id && 'underline pointer'}`} onClick={() => {
                if (user.id) {
                  userDetail(user.id)
                }
              }}>{user.name}</div>,
              <div className="flex-center-all h-full">{user.gender}</div>,
              <div className="flex-center-all h-full">{user.age}</div>,
              <div className="flex-center-all h-full">{user.entryPointName}</div>,
              <div className="flex-center-all h-full">{user.friendStatus}</div>,
              <div className="flex-center-all h-full">{user.productStatus}</div>,
              <div className="flex-center-all h-full">{user.paymentAmount.toLocaleString()}원</div>,
              <div className="flex-center-all h-full">{user.paymentDate}</div>,
            ]
          }))
    })
  }, [page, search, dateRange, isOffline]);

  const handleSearchChange = (search) => {
    setPage(1)
    setSearch(search);
  };

  const onDateInput = (dateRange) => {
    setPage(1)
    setDateRange({
      startDate: moment(dateRange.startDate).format("yyyy-MM-DD"),
      endDate: moment(dateRange.endDate).format("yyyy-MM-DD")
    });
  };

  const headers = [
    { name: "상품명", align: "center" },
    { name: "이름", align: "center", width: "100px" },
    { name: "성별", align: "center", width: "70px" },
    { name: "연령", align: "center", width: "110px" },
    { name: "회원가입", align: "center", width: "140px" },
    { name: "상태", width: "70px", align: "center" },
    { name: "주문 상태", width: "120px", align: "center" },
    { name: "결제 가격", width: "120px", align: "center" },
    { name: "결제 날짜", width: "120px", align: "center" },
  ];

  function userDetail(id) {
    window.location.href = `/user/detail?id=${id}`
  }

  return (
    <>
      <div className="flex-col rounded w-full">
        <div className="page-title">온라인 매출</div>
        <div className="flex items-end" style={{marginTop: "35px", gap: "24px"}}>
          <div style={{maxWidth: "405px", width: "405px"}}>
            <div style={{marginBottom: "9px"}}>
              <label className="search-label">검색어</label>
            </div>
            <SearchInput onSubmit={(text) => handleSearchChange(text)} />
          </div>
          <div>
            <div style={{marginBottom: "9px"}}>
              <label className="search-label">결제 날짜</label>
            </div>
            <CalendarBar initDate={{startDate: moment(dateRange.startDate).toDate(), endDate: moment(dateRange.endDate).toDate()}}
                         onConfirm={(props) => onDateInput(props)} />
          </div>
          <div className="h-full">
            <div style={{marginBottom: "9px"}}>
              <label className="search-label">카카오 고객</label>
            </div>
            <div className="flex-align-center gap-4" style={{height: "50%"}}>
              <div>카카오 고객만 보기</div>
              <div><Checkbox onChecked={(checked) => {
                setOffline(checked)
              }} /></div>
            </div>
          </div>
        </div>
        <div className="flex gap-8" style={{marginTop: "30px", marginLeft: "14px"}}>
          <span className="font-16px">
            총 <span className="mainblue-0">{totalCount.toLocaleString()}</span>명
          </span>
          <span className="font-16px">
            매출 <span className="mainblue-0">{totalSales.toLocaleString()}</span>원
          </span>
        </div>
        <div className="flex-col py-4">
          <Table headers={headers} data={tableUsers} />
          <div className="flex justify-center" style={{marginTop: "24px"}}>
            <Pagination rowSize={limit} totalSize={totalCount} page={page} onPaging={(page) => setPage(page)} />
          </div>
        </div>
      </div>
    </>
  );
};
export default OnlineOrderUsers;
