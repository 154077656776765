import {Button, Table, TextInput} from "omoplay";
import {useEffect, useState} from "react";
import {getAllSpots} from "../../../api/spot/spot.api";

export const EntrypointSimpleList = ({selectedEntrypointIds, onSelected}) => {
    const [entrypointList, setEntrypointList] = useState([])
    const [entrypointTableList, setEntrypointTableList] = useState([])
    const [checkedEntrypointList, setCheckedEntrypointList] = useState([])
    const rowSize = 10

    const headers = [
        { name: "ID", width: "60px" },{ name: "진입점명", sortable: false }, { name: "연결된 프로젝트", sortable: false }
    ]

    useEffect(() => {
        getAllSpots().then(({data}) => {
            const filterData = data.filter((entryPoint) => !selectedEntrypointIds.some(selectedEntryId =>
                    selectedEntryId === entryPoint.id
                )
            ).map(entryPoint => {
                return {
                    ...entryPoint,
                    id: entryPoint.id
                }
            })
            setEntrypointList(filterData)
            setEntrypointTableList(filterData.map(entrypoint => {
                return [
                    <div>{entrypoint.id}</div>,
                    <div>
                        <div>{entrypoint.name}</div>
                        <div>{entrypoint.updatedAt} 업데이트</div>
                    </div>,
                    <div>{ entrypoint.projectNames?.map(projectName => {
                        return (
                            <div>{projectName}</div>
                        )
                    })}</div>
                ]
            }))
        })
    }, [selectedEntrypointIds])

    return (
      <div className="flex-col gap-4" style={{marginTop: "24px"}}>
          <div className="flex justify-between">
              <TextInput showIcon={true} placeholder="검색어를 입력해 주세요." />
              <Button variants="dark" value="선택 연결" onClick={() => { onSelected(checkedEntrypointList) }} />
          </div>
          <div>
              <Table checkbox={true} headers={headers} data={entrypointTableList}
                     onCheckIndex={(index, checked) => {
                         const newCheckedEntrypointList = [...checkedEntrypointList]
                         if (checked) {
                             newCheckedEntrypointList.push(entrypointList.find((value, _index) => _index === index))
                             setCheckedEntrypointList(newCheckedEntrypointList)
                         } else {
                             setCheckedEntrypointList(newCheckedEntrypointList.filter((value, _index) => _index !== index))
                         }
                     }}
                     onCheckedAll={(page, checked) => {
                         if (checked) {
                             setCheckedEntrypointList([...entrypointList.filter((value, index) => index < page * rowSize && index >= (page - 1) * rowSize)])
                         } else {
                             setCheckedEntrypointList([])
                         }
                     }}
                     pagination={{
                          rowSize: rowSize,
                          totalSize: entrypointTableList.length
                      }}
              />
          </div>
      </div>
    )
}

export default EntrypointSimpleList
