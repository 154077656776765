import React from "react";
import {Button} from "omoplay";

const MiniDefaultTypeContent = ({ data }) => {
    return (
        <div style={{ width: "220px" }} className="message-content">
            <div className="default-message-content">
                <div style={{ whiteSpace: "break-spaces", fontSize: "12px" }}>
                    {data.content ?? "내용"}
                </div>
                {data.buttons?.filter((button) => button.type === "WL" || button.type === "COUPON")
                    .map((button, index) => {
                        return (
                            <Button
                                variants="grey"
                                onClick={() => window.open(button.link)}
                                style={{ marginTop: index === 0 ? 2 : 1, fontSize: "12px" }}
                                value={button.name || "메시지 버튼"}
                            >
                            </Button>
                        );
                    })}
            </div>
        </div>
    );
};

export default MiniDefaultTypeContent;
