import {useMemo, useState} from "react";
import Accordion from "../../Accordion";
import MessageWrapPreview from "./preview/MessageWrapPreview";
import { MESSAGE_TARGET_FILTER, MESSAGE_TYPES } from "../../../constants/menus";
import {validContent} from "../../../api/message/volatility-message.api";
import { getMessagePrice } from "../../../libs/helper";
import { floor } from "lodash";
import moment from "moment";

export const MessageConfirm = ({
    message,
    customerCount,
    onValidState
}) => {
  const [open, setOpen] = useState("setting");

  const messageData = message.messageData
  const filters = message.filters
  const filterCategories = useMemo(() => {
    return Array.from(new Set(filters?.map((item) => item.category)));
  }, [filters]);

  const issuedAt = message.issuedAt

  const campaignName = message.campaignName
  const type = messageData.type

  const defaultSettingValid = campaignName?.length > 0
  const contentSettingValid = validContent(messageData)

  const targetSettingValid = moment(issuedAt.date.startDate, "YYYY-MM-DD").hour(issuedAt.hour).minutes(issuedAt.min).isAfter(moment())

  onValidState(defaultSettingValid && contentSettingValid && targetSettingValid)

  return (
    <div className="border rounded-lg bg-white w-[1032px] p-8 mb-20">
      <div className="flex-col">
        <div className="py-2 border-b border-gray-300">
          <label className="text-lg font-semibold text-gray-900 relative">
            최종 확인!
          </label>
          <p className="text-gray-500 text-sm mb-2">
            마지막으로 보내기 전 설정하신 내용에 잘못된 내용은 없는지 확인해
            보세요.
          </p>
        </div>
        <ul className="flex-col gap-4 mt-4">
          <li>
            <Accordion
              title={"기본 설정"}
              isOpen={open === "default"}
              isError={!defaultSettingValid}
              errorText={"* 필수 설정이 완료되지 않았습니다"}
              onClick={() => setOpen(open === "default" ? null : "default")}
            >
              <ul className="flex-col divide-y divide-gray-300 text-sm p-4">
                <li className="p-4 flex items-center">
                  <label className="w-[120px] font-semibold">캠페인명</label>
                  <p>{campaignName}</p>
                </li>
                <li className="p-4 flex items-center">
                  <label className="w-[120px] font-semibold">메시지 타입</label>
                  <p>친구톡</p>
                </li>
                <li className="p-4 flex items-center">
                  <label className="w-[120px] font-semibold">친구톡 타입</label>
                  <p>
                    {type &&
                      MESSAGE_TYPES.filter(
                        (item) => item.key === type
                      )[0]?.title}
                  </p>
                </li>
              </ul>

              <div className="flex justify-end p-4">
                {/*
                <NormalButton
                  className="py-1.5 px-4 flex gap-2 items-center text-sm"
                  onClick={() => onClick("default")}
                >
                  <FontAwesomeIcon icon={faEdit} />
                  <p className="underline">수정하기</p>
                </NormalButton>
                */}
              </div>
            </Accordion>
          </li>
          <li>
            <Accordion
              title={"콘텐츠"}
              isOpen={open === "content"}
              isError={!contentSettingValid}
              errorText={"* 필수 설정이 완료되지 않았습니다"}
              onClick={() => setOpen(open === "content" ? null : "content")}
            >
              <div className="flex justify-center mt-4 flex-col items-center">
                <div className="rounded bg-gray-200 py-1.5 px-8 my-2 text-sm">
                  미리보기
                </div>
                <MessageWrapPreview messageData={messageData} type={type} />
              </div>

              <div className="flex justify-end p-4">
                {/*
                <NormalButton
                  className="py-1.5 px-4 flex gap-2 items-center text-sm"
                  onClick={() => onClick("content")}
                >
                  <FontAwesomeIcon icon={faEdit} />
                  <p className="underline">수정하기</p>
                </NormalButton>
                */}
              </div>
            </Accordion>
          </li>
          <li>
            <Accordion
              title={"대상 및 발송 일정"}
              isOpen={open === "setting"}
              isError={!targetSettingValid}
              errorText={"* 필수 설정이 완료되지 않았습니다"}
              onClick={() => setOpen(open === "setting" ? null : "setting")}
            >
              <div className="p-4 text-sm">
                <div className="flex-col divide-y">
                  <div>
                    {filterCategories && filterCategories.length > 0 && (
                      <label className="font-semibold">대상 조건</label>
                    )}
                    <ul className="flex-col gap-4 mt-4">
                      {filterCategories &&
                        filterCategories.map((value, i) => {
                          const categoryIndex = MESSAGE_TARGET_FILTER.findIndex(
                            (parent) => parent.value === value
                          );
                          return (
                            <li
                              key={"confirm_category_" + i}
                              className="flex gap-4 items-center"
                            >
                              <div className="border border-gray-300 rounded flex w-5/6">
                                <div className="min-w-[160px] bg-gray-100 p-4 border-r border-gray-300 flex">
                                  <p className="mt-1">
                                    {MESSAGE_TARGET_FILTER[categoryIndex].label}
                                  </p>
                                </div>
                                <div className="flex flex-wrap gap-4 px-4 py-4">
                                  {filters
                                    .filter((item) => item.category === value)
                                    .map((item, j) => {
                                      return (
                                        <div
                                          key={
                                            "confirm_category_" +
                                            i +
                                            "_item_" +
                                            j
                                          }
                                          className="rounded py-1 bg-indigo-100 text-indigo-600 px-4 font-medium"
                                        >
                                          {item.label}
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                              <div className="w-1/6 flex">
                                {i < filterCategories.length - 1 && (
                                  <p className="ml-4">
                                    이고{" "}
                                    <span className="font-semibold">(AND)</span>
                                  </p>
                                )}
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                    <div className="bg-gray-100 rounded my-4 py-4 px-6 flex items-center justify-between font-bold">
                      <p>예상 발송 수</p>
                      <p className="text-indigo-600">
                        {customerCount.toLocaleString()}(명)
                      </p>
                    </div>
                  </div>
                  <div className="py-4">
                    <div className="flex gap-8">
                      <label className="font-semibold">발송 날짜/시간</label>
                      <div className="flex gap-2">
                        <div>발송등록</div>
                        <div>
                          {
                            `${issuedAt.date.startDate} ${issuedAt.hour}시 ${issuedAt.min}분`
                          }
                        </div>
                        <div>
                          {
                              !targetSettingValid && (<span className="text-red-500">* 예약 날짜는 과거로 설정할 수 없습니다.</span>)
                          }
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-100 w-full rounded px-4 py-0.5 mt-4 mb-6">
                      <p className="text-gray-500 text-[12px] my-2">
                        * 한국 사용자는{" "}
                        <span className="text-red-600">
                          20:55부터 08:00까지는 메시지 수신이 불가합니다.{" "}
                        </span>{" "}
                        해외 사용자에게는 시간 제한 없이 보내지니 참고해 주세요.
                      </p>
                    </div>
                  </div>
                  <div className="pt-4">
                    <label className="font-semibold">총 발송 비용</label>
                    <div className="bg-gray-100 w-full rounded p-4 my-4 flex-col gap-4">
                      <div className="flex gap-4 items-center">
                        <label className="font-semibold w-[100px]">
                          유료 발송 수
                        </label>
                        <p>{customerCount.toLocaleString()}건</p>
                      </div>

                      <div className="flex gap-4 items-center">
                        <label className="font-semibold w-[100px]">
                          발송 비용 합계
                        </label>
                        <p>
                          {getMessagePrice(type)}원 *{" "}
                          {customerCount.toLocaleString()}건 + VAT ={" "}
                          {floor(
                            customerCount *
                              1.1 *
                              getMessagePrice(type)
                          ).toLocaleString()}
                        </p>
                      </div>
                    </div>
                    <ul className="text-xs text-gray-400">
                      <li>
                        * 메시지 차단 등으로 인해 예상 발송 수와 실발송 수가
                        다를 수 있습니다.
                      </li>
                      <li>
                        * 메시지 발송등록 시 과금된 비용과 실발송 후의 차액은
                        발생할 수 있으며, 발송되지 않은 발송 건은 청구되지
                        않습니다.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="flex justify-end p-4">
                {/*
                <NormalButton
                  className="py-1.5 px-4 flex gap-2 items-center text-sm"
                  onClick={() => onClick("setting")}
                >
                  <FontAwesomeIcon icon={faEdit} />
                  <p className="underline">수정하기</p>
                </NormalButton>
                */}
              </div>
            </Accordion>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MessageConfirm;
