import Layout from "../components/layout/Layout";
import ProductList from "../components/page/ProductList";

const ProductListPage = () => {
  return (
    <Layout>
      <ProductList />
    </Layout>
  );
};
export default ProductListPage;
