import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AllSpotsPage from "./pages/AllSpots";
import Home from "./pages/EntryPointPage";
import Login from "./pages/Login";
import PromotionPage from "./pages/PromotionPage";
import UserStatistics from "./pages/UserStatistics";
import UserListPage from "./pages/Users";
import Coupons from "./pages/Coupons";
import VolatilityMessageDetail from "./pages/VolatilityMessageDetail";
import Payments from "./pages/Payments";
import "moment/locale/ko";
import "omoplay/dist/style.scss"
import MessagePage from "./pages/Messages";
import AutomateMessages from "./pages/AutomateMessages";
import AutomateMessageEditPage from "./pages/AutomateMessageEditPage";
import AccountSettingPage from "./pages/AccountSettingPage";
import ProjectsPage from "./pages/Projects";
import ProjectSettings from "./pages/ProjectSettings";
import EventEdit from "./pages/EventEdit";
import EventPage from "./pages/EventPage";
import BenefitEdit from "./pages/BenefitEdit";
import ProjectDetailPage from "./pages/ProjectDetail";
import VolatilityMessageSettingsPage from "./pages/VolatilityMessageSettingsPage";
import PointMenuPage from "./pages/PointMenuPage";
import UserDetailPage from "./pages/UserDetail";
import OnlineOrderUsersPage from "./pages/OnlineOrderUsersPage";
import ProductListPage from "./pages/ProductListPage";
import ProductDetailPage from "./pages/ProductDetailPage";
import AIVolatilityMessageListPage from "./pages/AIVolatilityMessageListPage";
import VolatilityMessageListPage from "./pages/VolatilityMessageListPage";
import AIVolatilityMessageEditPage from "./pages/AIVolatilityMessageEditPage";
import PaymentFail from "./pages/PaymentFail";
import PaymentBilling from "./pages/PaymentBilling";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AllSpotsPage />} />
        <Route path="/spots" element={<Home />} />
        <Route path="/event" element={<EventPage />} />
        <Route path="/event/edit" element={<EventEdit />} />
        <Route path="/benefit/edit" element={<BenefitEdit />} />
        <Route path="/volatility-message" element={<VolatilityMessageListPage />} />
        <Route
          path="/volatility-message/edit"
          element={<VolatilityMessageSettingsPage />}
        />
        <Route
          path="/volatility-message/detail"
          element={<VolatilityMessageDetail />}
        />
        <Route path="/benefit" element={<Coupons />} />
        <Route path="/promotion" element={<PromotionPage />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/users" element={<UserListPage />} />
        <Route path="/user/detail" element={<UserDetailPage />} />
        <Route path="/messages" element={<MessagePage />} />
        <Route path="/users-statistics" element={<UserStatistics />} />
        <Route path="/automate-message" element={<AutomateMessages />} />
        <Route path="/automate-message/edit" element={<AutomateMessageEditPage />} />
        <Route path="/project" element={<ProjectsPage />} />
        <Route path="/project/edit" element={<ProjectSettings />} />
        <Route path="/project/detail" element={<ProjectDetailPage />} />
        <Route path="/point-menu" element={<PointMenuPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/account" element={<AccountSettingPage />} />
        <Route path="/online/orders" element={<OnlineOrderUsersPage />} />
        <Route path="/product" element={<ProductListPage />} />
        <Route path="/product/detail" element={<ProductDetailPage />} />
        <Route path="/ai/volatility-message" element={<AIVolatilityMessageListPage />} />
        <Route path="/ai/volatility-message/edit" element={<AIVolatilityMessageEditPage />} />
        <Route path="/payment/fail" element={<PaymentFail />} />
        <Route path="/payment/billing" element={<PaymentBilling />} />
      </Routes>
    </Router>
  );
}

export default App;
