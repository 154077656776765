import InputGroup from "../../../form/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import {MESSAGE_TYPES} from "../../../../constants/menus";
import {TabBar, Textarea, TextInput} from "omoplay";
import TypeSelectionForm from "../../../form/TypeSelectionForm";
import Badge from "../../badge/Badge";
import {useEffect, useState} from "react";

export const AIMessageDefaultInfo = ({campaignName, type, objective, detailObjective,
                                       emptyMessage, onChangeCampaignName, onChangeMessageType,
                                       onChangeObjective, onChangeDetailObjective}) => {
  const [tab, setTab] = useState("campaign")
  const [objectiveItems, setObjectiveItems] = useState([
    {label: "제품 판매 촉진 (매출)"}, { label: "이벤트/미션 진행 (인게이지먼트)"}, { label: "안내/공지 (정보 전달)"}, { label: "브랜딩 콘텐츠 (인지/홍보)"}, { label: "고객의 목소리 (VOC)"}, { label: "정기/자동화 메시지 (관계형성)"}
  ])
  const [detailObjectiveItems, setDetailObjectiveItems] = useState([
    {label: "신제품 세일"}, { label: "정기 세일"}, { label: "클리어런스 세일"}, { label: "오프라인 재방문 구매 할인"}, { label: "온라인 재방문 구매 할인"}, { label: "타임 세일"}, { label: "재입고 알림"}, { label: "특가 세일"}
  ])

  const tabBarItems = [
    {
      label: <div className="flex gap-4"><div>켐페인 정보</div></div>,
      value: "campaign",
      selected: true
    },
    {
      label: <div className="flex gap-4"><div>메시지 목적</div>{(!objective || !detailObjective) && <Badge name="설정 필요" color="red" />}</div>,
      value: "objective"
    },
    {
      label: <div className="flex gap-4"><div>메시지 타입</div>{type === "" && <Badge name="설정 필요" color="red" />}</div>,
      value: "type"
    }
  ]

  useEffect(() => {
    if (tab === "objective") {
      setObjectiveItems((prev) => {
        const index = prev.findIndex(item => item.label === objective)
        if (index !== -1) {
          prev[index].selected = true
        }
        return [...prev]
      })
      setDetailObjectiveItems((prev) => {
        const index = prev.findIndex(item => item.label === detailObjective)
        if (index !== -1) {
          prev[index].selected = true
        }
        return [...prev]
      })
    }
  }, [tab]);

  return (
    <>
      <div className="flex-col mt-8">
        <TabBar items={tabBarItems} onChanged={(item) => setTab(item.value)} />
        {
          tab === "campaign" && (
              <>
                <InputGroup
                    label={"메시지 캠페인명"}
                    description="다른 메시지들과 구분하기 위한 캠페인명을 작성해 주세요."
                    className="py-8"
                >
                  <TextInput
                      value={campaignName}
                      placeholder="캠페인명을 입력해 주세요."
                      maxLength={40}
                      onChange={(e) => onChangeCampaignName(e.target.value)}
                  />
                </InputGroup>
                <InputGroup
                    label={"메시지 캠페인 설명(선택사항)"}
                    description="주요 키워드만 입력해 놓아도 추후 내용을 파악하는데 도움이 됩니다."
                    className="py-8"
                >
                  <Textarea
                      placeholder="캠페인 설명을 입력해 주세요."
                      maxLength={1000}
                      onChange={(e) => console.log("")}
                  />
                </InputGroup>
              </>
            )
        }
        {
          tab === "objective" && (<>
              <div className="p-8 pt-0">
                <InputGroup
                    label={"메시지 목적/목표"}
                    description="메시지 캠페인의 목적을 선택하면, 그에 맞는 콘텐츠를 생성할 수 있습니다."
                    className="py-8"
                >
                  <div className="border-gray-200 bg-gray-100 border">
                    <div className="flex divide-x border-b border-gray-200">
                      <div className="w-1/3 h-full">
                        <ul className="flex-col gap-2 flex p-4 overflow-auto max-h-[298px]">
                          {objectiveItems.map((item, index) => {
                            const isActive = item.selected ?? false
                            return (
                                <li
                                    key={"filter_depth_1_" + index}
                                    className={
                                        "rounded w-full py-3 px-4 border text-sm cursor-pointer " +
                                        (isActive
                                            ? "bg-indigo-50 border-indigo-600 text-indigo-600 font-semibold "
                                            : "bg-white border-white text-gray-600")
                                    }
                                    onClick={() => {
                                      setObjectiveItems((prev) => {
                                        for (let i = 0; i < prev.length; i++) {
                                          prev[i].selected = false
                                        }
                                        prev[index].selected = true
                                        onChangeObjective(prev[index].label)
                                        return [...prev]
                                      })
                                    }}>
                                  {item.label}
                                </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="w-1/3 h-full">
                        <ul className="flex-col gap-2 flex p-4 overflow-auto max-h-[298px]">
                          {detailObjectiveItems.map((item, index) => {
                            const isActive = item.selected ?? false

                            return (
                                <li key={"filter_depth_2_" + index} className={
                                    "rounded w-full py-3 px-4 border text-sm cursor-pointer " +
                                    (isActive
                                        ? "bg-indigo-50 border-indigo-600 text-indigo-600 font-semibold "
                                        : "bg-white border-white text-gray-600")
                                }
                                    onClick={() => {
                                      setDetailObjectiveItems((prev) => {
                                        for (let i = 0; i < prev.length; i++) {
                                          prev[i].selected = false
                                        }
                                        prev[index].selected = true
                                        onChangeDetailObjective(prev[index].label)
                                        return [...prev]
                                      })
                                    }}>
                                  {item.label}
                                </li>
                            );
                          }
                          )}
                        </ul>
                      </div>
                      <div className="w-1/3 h-full">
                        <ul className="flex-col gap-2 flex p-4 overflow-auto max-h-[298px]">
                          <div>
                            {detailObjectiveItems.filter(value => value.selected)[0]?.label ?? ""}
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </InputGroup>
              </div>
            </>)
        }
        {
            tab === "type" && (
                <>
                  <InputGroup label={"메시지 타입"} isRequired={true} className="py-8">
                    <p className="text-gray-500 text-sm mb-2">
                      어떤 메시지를 보낼지 골라주세요
                    </p>
                    <ul className="flex w-full gap-8">
                      <li className="border-indigo-500 bg-indigo-50 py-6 w-[200px] rounded-lg flex gap-2 items-center text-gray-600 border justify-center cursor-pointer">
                        <div className="rounded-full bg-gray-200 w-[48px] h-[48px] flex items-center justify-center text-indigo-600">
                          <FontAwesomeIcon icon={faComment} size={"lg"}></FontAwesomeIcon>
                        </div>
                        <p className="font-medium">친구톡</p>
                      </li>
                    </ul>
                  </InputGroup>
                  <InputGroup
                      label={"친구톡 타입 고르기"}
                      isRequired={true}
                      className="py-8"
                  >
                    <p className="text-gray-500 text-sm mb-2">
                      어떤 메시지를 보내실지 골라주세요.
                      {
                        !emptyMessage && (<span className="ml-2 text-red-500">* 이미 AI 메시지가 생성된 경우 수정할 수 없습니다.</span>)
                      }
                    </p>
                    <TypeSelectionForm types={MESSAGE_TYPES} selectedType={type === "FT" ? "FI" : type} disabled={!emptyMessage} onChangeType={onChangeMessageType} />
                  </InputGroup>
                </>
            )
        }
      </div>
    </>
  );
};

export default AIMessageDefaultInfo;
