import {
    addProduct,
    getProductList,
    getTextHistory,
    createMessageGeneration,
    messageChatInitStreamGeneration,
    messageChatStreamGeneration, updateProducts, saveAIMessage
} from "../../api/ai/ai.api";
import {useEffect, useRef, useState} from "react";
import MessageWrapPreview from "../volatility-message/content/preview/MessageWrapPreview";
import {Button, TextInput} from "omoplay";
import SuspensePopup from "../SuspensePopup";
import ProductConnectionList from "../form/ProductConnectionList";
import MiniCarouselTypeContent from "../volatility-message/content/preview/MiniCarouselTypeContent";
import {useSearchParams} from "react-router-dom";


const AIMessageEdit = ({message, onChangeMessageData}) => {
    const [query] = useSearchParams();
    const [messages, setMessages] = useState([])
    const [blockGenerate, setBlockGenerate] = useState(true)
    const [input, setInput] = useState("")
    const [initDone, setInitDone] = useState(false)
    const [existProducts, setExistsProducts] = useState(false)
    const scrollRef = useRef(null)
    const [productConnect, setProductConnect] = useState(false)
    const [productsConnect, setProductsConnect] = useState(false)
    const [lastMessageData, setLastMessageData] = useState({})
    const [isCommandKeyPressed, setIsCommandKeyPressed] = useState(false);

    const id = query.get("id");

    function getHistories() {
        getTextHistory(id).then(async ({data}) => {
            setInitDone(data.initDone)
            setMessages([])
            if (data.initDone) {
                data.history.histories.forEach(history => {
                    const data = history.data
                    if (typeof data !== "string") {
                        setLastMessageData(data)
                        onChangeMessageData(data)
                        setMessages((prevMessages) => [...prevMessages,
                            <MessageWrapPreview messageData={data} type={data.type}/>]);
                    } else {
                        setMessages((prevMessages) => [...prevMessages,
                            <div className={`mt-4 whitespace-pre ${history.type === "user" && "text-right"}`}>{data}</div>]);
                    }
                })
            } else {
                for (const history of data.initializeHistory.histories) {
                    const data = history.data
                    if (data.includes("productIds")) {
                        const json = JSON.parse(data)
                        setExistsProducts(true)
                        const {data: productData} = await getProductList(json.productIds);
                        setMessages((prevMessages) => [...prevMessages,
                            <div className="mt-4 bg-gray-200">
                                {
                                    productData.map(product => {
                                        const info = product.info
                                        return (<div className="flex p-4 bg-white border rounded">
                                            <div className="flex-center-all flex-1 border">
                                                <div><img src={info.images[0]} width="48px" height="48px"/></div>
                                                <div>{info.productName}</div>
                                            </div>
                                            <div className="flex-center-all flex-1 border">{info.category}</div>
                                            <div
                                                className="flex-center-all flex-1 border">{info.originalPrice?.toLocaleString()}</div>
                                            <div className="flex-center-all flex-1 border">{product.createdAt}</div>
                                        </div>)
                                    })
                                }
                            </div>
                        ]);
                    } else if (data.includes("reProductIds")) {
                        const json = JSON.parse(data)
                        const {data: productData} = await getProductList(json.reProductIds);
                        setMessages((prevMessages) => [...prevMessages,
                            <div className="mt-4 bg-gray-200">
                                {
                                    productData.map(product => {
                                        const info = product.info
                                        return (<div className="flex p-4 bg-white border rounded">
                                            <div className="flex-center-all flex-1 border">
                                                <div><img src={info.images[0]} width="48px" height="48px"/></div>
                                                <div>{info.productName}</div>
                                            </div>
                                            <div className="flex-center-all flex-1 border">{info.category}</div>
                                            <div
                                                className="flex-center-all flex-1 border">{info.originalPrice?.toLocaleString()}</div>
                                            <div className="flex-center-all flex-1 border">{product.createdAt}</div>
                                        </div>)
                                    })
                                }
                            </div>
                        ]);
                    } else if (data.includes("productId")) {
                        const json = JSON.parse(data)
                        const {data: productData} = await getProductList(json.productId);
                        setMessages((prevMessages) => [...prevMessages,
                            <div className="mt-4 bg-gray-200">
                                {
                                    productData.map(product => {
                                        const info = product.info
                                        return (<div className="flex p-4 bg-white border rounded">
                                            <div className="flex-center-all flex-1 border">
                                                <div><img src={info.images[0]} width="48px" height="48px"/></div>
                                                <div>{info.productName}</div>
                                            </div>
                                            <div className="flex-center-all flex-1 border">{info.category}</div>
                                            <div
                                                className="flex-center-all flex-1 border">{info.originalPrice?.toLocaleString()}</div>
                                            <div className="flex-center-all flex-1 border">{product.createdAt}</div>
                                        </div>)
                                    })
                                }
                            </div>
                        ]);
                    } else {
                        setMessages((prevMessages) => [...prevMessages,
                            <div className={`mt-4 whitespace-pre ${history.type === "user" && "text-right"}`}
                                 onClick={testAICreateFun}
                                 dangerouslySetInnerHTML={{__html: data}}>
                            </div>]);
                    }
                }}
        })
    }

    const streamGeneration = (text) => {
        setBlockGenerate(true)
        setInput("")
        // Enter 키를 누르면 메시지를 추가
        setMessages((prevMessages) => [...prevMessages, <div className="text-right">{text}</div>]);

        const eventSource = initDone ? messageChatStreamGeneration(id, text) : messageChatInitStreamGeneration(id, text)

        let curMessage = "";

        setMessages((prevMessages) => [
            ...prevMessages,
            <div>{curMessage}</div>,
        ]);

        // 서버에서 데이터가 올 때마다 처리
        eventSource.onmessage = (event) => {
            curMessage += event.data; // 새로 온 데이터를 이어 붙임

            setMessages((prevMessages) => {
                // 마지막 메시지만 curMessage로 업데이트
                const updatedMessages = [...prevMessages];
                updatedMessages[updatedMessages.length - 1] = <div onClick={testAICreateFun} dangerouslySetInnerHTML={{__html: curMessage}}></div>;
                return updatedMessages;
            });
        };

        // 에러 발생 시
        eventSource.onerror = (error) => {
            setTimeout(() => {
                getHistories()
                setBlockGenerate(true)
                eventSource.close();
            }, 1000)
        };

        // 서버에서 스트리밍이 종료되었을 때
        eventSource.onclose = () => {
            console.log('Stream closed');
        };
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !isCommandKeyPressed && input.trim() !== "" && !blockGenerate) {
            streamGeneration(input)
        }

        if (e.key === "Shift") {
            setIsCommandKeyPressed(true)
        }
    }

    const handleKeyUp = (e) => {
        setBlockGenerate(input.trim() === "")

        if (e.key === "Shift") {
            setIsCommandKeyPressed(false)
        }
    }

    const handleClick = () => {
        if (input.trim() !== "" && !blockGenerate) {
            streamGeneration(input)
        }
    }

    function testAICreateFun(event) {
        const button = event.target.closest("button[data-action]");
        if (button) {
            event.stopPropagation()
            const action = button.dataset.action
            if (action === "productsConnect" && !existProducts) {
                setProductsConnect(true)
            } else if (action === "productReConnect" && existProducts) {
                setProductConnect(true)
            } else if (action === "confirmCreate" && !button.disabled) {
                button.disabled = true
                createMessageGeneration(id).then(() => {
                    setTimeout(() => {
                        getHistories()
                    }, 1000)
                })
            } else if (action === "productConnect") {
                setProductConnect(true)
            } else if (action === "imageType") {
                streamGeneration("이미지 추가")
            } else if (action === "textType") {
                streamGeneration("특정 주제로 콘텐츠 생성하기")
            }
        }
    }

    useEffect(() => {
        saveAIMessage(id, message).then(() => {
            getHistories()
        })
    }, []);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <div className="flex-col h-full">
            <div className="w-full border-b" style={{padding: "16px 24px"}}>
                {
                    lastMessageData.type ? <MiniCarouselTypeContent data={lastMessageData} /> : <div>아직 생성된 콘텐츠가 없습니다.</div>
                }
            </div>
            <div className="flex-col flex-1 justify-end w-full overflow-y-hidden">
                <div className="p-8 overflow-y-scroll" ref={scrollRef}>
                    {messages.map((message) => (<>{message}</>))}
                </div>
                <div className="flex p-[24px]">
                    <div className="flex-1 gap-2">
                        {/*
                        <Textarea placeholder="AI에게 질문 해보세요."
                                  value={input}
                                  onChange={(e) => setInput(e.target.value)}
                                  onKeyDown={handleKeyDown}
                                  onKeyUp={handleKeyUp}
                        />
                        */}
                        <TextInput placeholder="AI에게 질문 해보세요."
                                  value={input}
                                  onChange={(e) => setInput(e.target.value)}
                                  onKeyDown={handleKeyDown}
                                  onKeyUp={handleKeyUp}
                        />
                    </div>
                    <div className="flex-center-all">
                        <Button disabled={blockGenerate} variants="solid" value="↑" onClick={handleClick} />
                    </div>
                </div>
            </div>
            {
                (productsConnect || productConnect) && (
                    <SuspensePopup
                        isEditPopup={true}
                        title={"제품 연결하기"}
                        size={"w-[1200px]"}
                        onClose={() => {
                            setProductConnect(false)
                            setProductsConnect(false)
                        }}
                    >
                        <ProductConnectionList isMultiple={productsConnect} onConfirm={(ids) => {
                            setProductConnect(false)
                            setProductsConnect(false)
                            if (existProducts && productConnect) {
                                addProduct(id, ids).then(() => {
                                    streamGeneration(`{"reProductIds":[${ids}]}`)
                                })
                            } else if (productConnect) {
                                addProduct(id, ids).then(() => {
                                    streamGeneration(`{"productId":[${ids}]}`)
                                })
                            } else {
                                updateProducts(id, ids).then(() => {
                                    streamGeneration(`{"productIds":[${ids}]}`)
                                })
                            }
                        }}
                        />
                    </SuspensePopup>
                )
            }
        </div>
    )
}

export default AIMessageEdit