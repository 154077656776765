import React from "react";
import {Button} from "omoplay";

const MiniWideTypeContent = ({ data }) => {
    const image = data.image ?? {}
    return (
        <div style={{ width: "262px" }} className="message-content">
            <div className="default-message-content">
                <div style={{ whiteSpace: "break-spaces", fontSize: "12px" }}>
                    {data.content ?? "내용"}
                </div>
                <div className="flex-center-all" style={{padding: "8px", gap: "5px"}}>
                    {data.buttons?.filter((button) => button.type === "WL" || button.type === "COUPON")
                        .map((button) => {
                            return (
                                <Button
                                    style={{flex: "1", fontSize: "12px"}}
                                    variants="grey"
                                    onClick={() => window.open(button.link)}
                                    value={button.name || "메시지 버튼"}
                                />
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default MiniWideTypeContent;
