import { faMessage } from "@fortawesome/free-solid-svg-icons";
import SettingsLayout from "../../../layout/SettingsLayout";
import { useSearchParams } from "react-router-dom";
import {useEffect, useState} from "react";
import {saveNotification} from "../../../../api/message/volatility-message.api";
import SettingsStepLayout from "../../../layout/SettingsStepLayout";
import MessageTargetSetting from "../../../volatility-message/MessageTargetSetting";
import MessageConfirm from "../../../volatility-message/content/MessageConfirm";
import moment from "moment";
import {getAIMessage} from "../../../../api/ai/ai.api";
import AIMessageDefaultInfo from "./AIMessageDefaultInfo";
import AIMessageEdit from "../../../page/AIMessageEdit";
import MessageContentsSettings from "../../../volatility-message/content/setting/MessageContentsSettings";
import MessageWrapPreview from "../../../volatility-message/content/preview/MessageWrapPreview";
import MessageVariantsSetting from "../../../volatility-message/MessageVariantsSetting";

const tabs = [
  { label: "기본 설정", value: "default" },
  { label: "AI 생성", value: "ai" },
  { label: "콘텐츠 작성", value: "contents" },
  { label: "발송 대상", value: "target" },
  { label: "AI 맞춤 메시지", value: "variants" },
  { label: "최종확인", value: "confirm" },
];
const VolatilityMessageSettings = () => {
  const [query] = useSearchParams();
  const [tab, setTab] = useState(tabs[0].value);
  const [message, setMessage] = useState({
    type: "FT", messageData: { type: "FT" }
  })
  const [isConfirm, setIsConfirm] = useState(false)
  const [disabledTempBtn, setDisabledTempBtn] = useState(false)
  const [customerCnt, setCustomerCnt] = useState(0)
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)

  const id = query.get("id");

  useEffect(() => {
    if (id) {
      getAIMessage(id).then(({data}) => {
        setNextButtonDisabled(data.emptyMessage)
        const reserved = moment(data.issuedAt, "YYYY-MM-DD HH:mm")
        data.issuedAt = {
          date: {
            startDate: reserved.format("YYYY-MM-DD"),
            endDate: reserved.format("YYYY-MM-DD")
          },
          hour: String(reserved.hours()).padStart(2, "0"),
          min: String(reserved.minutes()).padStart(2, "0")
        }
        data.isAI = true
        setMessage(data)
      })
    }
  }, [id]);


  const handleConfirm = async (status) => {
    setIsConfirm(false)
    setDisabledTempBtn(false)
    saveNotification(status, message).then(() => {
      alert(
          status === "RESERVED" ? "발송이 등록되었습니다." : "임시 저장되었습니다."
      );
      window.location.replace("/ai/volatility-message");
    })
  };

  const updateMessage = (key, value) => {
    const newMessage = { ...message }
    newMessage[key] = value
    setMessage(newMessage)
  }

  const updateMessageData = (key, value) => {
    const newMessage = { ...message }
    const messageData = newMessage.messageData ?? {}
    messageData[key] = value
    newMessage.messageData = messageData
    setMessage(newMessage)
  }

  const updateMessageDataArrays = (arrayKeys, value) => {
    const newMessage = { ...message }
    const messageData = newMessage.messageData ?? {}

    let currentLevel = messageData;
    arrayKeys.forEach((arrayKey, idx) => {
      const isIndex = !isNaN(Number(arrayKeys[idx + 1]))
      currentLevel[arrayKey] = currentLevel[arrayKey] ?? (isIndex ? [] : {})

      if (idx < arrayKeys.length - 1) {
        currentLevel = currentLevel[arrayKey]
      } else {
        currentLevel[arrayKey] = value
      }
    })
    newMessage.messageData = messageData
    setMessage(newMessage)
  }

  const deleteMessageDataArrays = (index, arrayKeys) => {
    const newMessage = { ...message }
    const messageData = newMessage.messageData ?? {}
    messageData[arrayKeys] = messageData[arrayKeys] ?? []
    messageData[arrayKeys] = messageData[arrayKeys].filter((_, i) => i !== index)
    newMessage.messageData = messageData
    setMessage(newMessage)
  }

  const description = () => {
    return ""
  }

  useEffect(() => {
    if (message.campaignName != null && message.messageType != null &&
        message.objective != null && message.detailObjective != null) {
      setNextButtonDisabled(false);
    }
  }, [message]);

  const render = () => {
    const messageData = message.messageData ?? {}
    const type = messageData.type
    const filters = message.filters ?? []
    const issuedAt = message.issuedAt

    if (tab === "default") {
      return (
          <div className="setting-step-padding">
            <AIMessageDefaultInfo campaignName={message.campaignName}
                                  objective={message.objective}
                                  detailObjective={message.detailObjective}
                                  type={type}
                                  emptyMessage={message.emptyMessage}
                                  onChangeCampaignName={(campaignName) => {
                                    updateMessage("campaignName", campaignName)
                                  }}
                                  onChangeMessageType={(type) => {
                                    updateMessageData("type", type)
                                  }}
                                  onChangeObjective={(objective) => {
                                    updateMessage("objective", objective)
                                  }}
                                  onChangeDetailObjective={(objective) => {
                                    updateMessage("detailObjective", objective)
                                  }}
            />
          </div>
      )
    } else if (tab === "ai") {
      return (<AIMessageEdit message={message} onChangeMessageData={(data) => {
        const newMessage = {...message}
        newMessage.messageData = data
        setMessage(newMessage)
      }}/>)
    } else if (tab === "contents") {
      return (
          <div style={{display: "flex", gap: "60px", marginTop: "20px"}}>
            <div className="setting-step-padding" style={{flex: "1.5"}}>
              <MessageContentsSettings type={type}
                                       messageData={messageData}
                                       onChangeMessageData={(key, value) => updateMessageData(key, value)}
                                       onChangeImage={(imgUrl) => {
                                         const newMessage = {...message}
                                         const messageData = newMessage.messageData ?? {}
                                         messageData["type"] = imgUrl === undefined ? "FT" : "FI"
                                         if (!messageData.image) {
                                           messageData["image"] = {}
                                         }
                                         messageData["image"]["imgUrl"] = imgUrl
                                         newMessage.messageData = messageData
                                         setMessage(newMessage)
                                       }}
                                       onChangeMessageDataArrays={(arrayKeys, value) => updateMessageDataArrays(arrayKeys, value)}
                                       onDeleteMessageDataArrays={(index, arrayKeys) => deleteMessageDataArrays(index, arrayKeys)}
              />
            </div>
            <div style={{flex: "1"}}>
              <MessageWrapPreview type={type} messageData={messageData} fixed={true}/>
            </div>
          </div>
      )
    } else if (tab === "target") {
      return (
          <MessageTargetSetting
              id={id}
              targetFilters={filters}
              reservedDate={issuedAt}
              targetType={message.targetType}
              onChangeTargetType={(type) => {
                updateMessage("targetType", type)
              }}
              onChangeTargetFilters={(filters) => {
                updateMessage("filters", filters)
              }}
              onChangeCustomerCnt={(count) => {
                setCustomerCnt(count)
              }}
              type={type}
              messageData={messageData}
              onChangeReservedDate={(issuedAt) => {
                updateMessage("issuedAt", issuedAt)
              }}
              onChangeAdult={(flag) => {
                updateMessageData("adult", flag)
              }}
              onChangeSelectUserIds={(userIds) => {
                updateMessage("selectUserIds", userIds)
              }}
          />
      )
    } else if (tab === "variants") {
      return (
          <MessageVariantsSetting id={id} onChangeVariants={(key, value) => updateMessage(key, value)} />
      )
    } else if (tab === "confirm") {
      return (
          <MessageConfirm
              message={message}
              customerCount={customerCnt}
              onValidState={setIsConfirm}
          />
      )
    }
  };

  return (
    <SettingsLayout
      title={"메시지 보내기"}
      icon={faMessage}
      tabs={tabs}
      activeTab={tab}
      isTemporarySave={!disabledTempBtn}
      onTemporarySave={() => handleConfirm("TEMPORARY")}
      onConfirm={() => handleConfirm("RESERVED")}
      onChange={setTab}
      nextButtonDisabled={nextButtonDisabled}
      isConfirm={isConfirm}
      confirmText={"등록하기"}
      onClose={() => window.location.href = ("/ai/volatility-message")}
    >
      <SettingsStepLayout description={description()} renderChild={render()} />
    </SettingsLayout>
  );
};
export default VolatilityMessageSettings;
