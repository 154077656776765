import Layout from "../components/layout/Layout";
import AIVolatilityMessageList from "../components/page/AIVolatilityMessageList";

const AIVolatilityMessageListPage = () => {
  return (
    <Layout>
      <AIVolatilityMessageList />
    </Layout>
  );
};
export default AIVolatilityMessageListPage;
