import {Button} from "omoplay";
import React from "react"

const MiniWideListTypeContent = ({ data }) => {

  const header = data.header
  const items = data.items ?? []
  const buttons = data.buttons ?? []

  const handleImageClick = (data) => {
    if (data?.imgLink) {
      window.open(data.imgLink);
    }
  }

  return (
      <div
          style={{ width: "246px" }}
          className="message-content default-message-content"
      >
        <div style={{textAlign: "center", fontWeight: "600", color: "grey"}}>
          {header || "타이틀"}
        </div>
        <div
            className="preview-image-wrap clickable wide-list"
            style={{
              width: "100%",
              height: "115px",
              backgroundImage: items[0]?.imgUrl
                  ? `url(${items[0]?.imgUrl})`
                  : "linear-gradient( 0deg, rgb(145 143 143), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) );",
            }}
            onClick={() => handleImageClick(items.get(0))}
        >
          <div style={{ color: "#FFF", padding: "2px", fontSize: "14px" }}>
            {items[0]?.title}
          </div>
        </div>
        <div style={{ marginTop: "2px" }}>
          {Array.from({ length: Math.max(2, items.length - 1) }, (_, index) => {
            const item = items[index + 1] ?? {}
            return (
                <div
                    key={"wide-list-type-content" + index}
                    style={{
                      display: "flex",
                      gap: 1,
                      marginTop: "1px",
                      alignItems: "center",
                    }}
                >
                  <div
                      className="preview-list-wrap clickable"
                      onClick={() => handleImageClick(item)}
                      style={{
                        backgroundImage: `url(${item.imgUrl})`,
                      }}
                  ></div>
                  <div
                      style={{
                        width: "174px",
                      }}
                  >
                    <div
                        style={{ wordBreak: "break-word", fontSize: "12px" }}
                    >
                      {item.title}
                    </div>
                  </div>
                </div>
            );
          })}
        </div>
        <div className="flex-center-all" style={{padding: "8px", gap: "5px"}}>
          {buttons?.filter((button) => button.type === "WL" || button.type === "COUPON")
              .map((button) => {
                return (
                    <Button
                        style={{flex: "1", fontSize: "12px"}}
                        variants="grey"
                        onClick={() => window.open(button.link)}
                        value={button.name || "메시지 버튼"}
                    />
                );
              })}
        </div>
      </div>
  );
};

export default MiniWideListTypeContent;
