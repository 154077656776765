import {Button, Table, TextInput} from "omoplay";
import {getProductList, productRegistration} from "../../api/ai/ai.api";
import React, {useEffect, useState} from "react";

const ProductList = () => {
    const [url, setUrl] = useState("")
    const [products, setProducts] = useState([])

    useEffect(() => {
        getProductList().then(({data}) => {
            setProducts(data.map(product => {
                const info = product.info
                let loadingDiv = <div className="loading-spinner"></div>

                if (info) {
                    loadingDiv = <div className="flex-align-center pointer gap-2 underline" onClick={() => {
                        window.location.href = `/product/detail?id=${product.id}`
                    }}>
                        <div><img width={60} height={60} src={info.images[0]} /></div>
                        <div>{info.productName}</div>
                    </div>
                }
                return [
                    <div>{product.id}</div>,
                    loadingDiv,
                    <div className="break-all">{product.url}</div>,
                    <div>{product.createdAt}</div>
                ]
            }))
        })
    }, []);

    function registration() {
        productRegistration(url).then(() => {
            alert("자동 등록을 호출 했습니다. 완료되면 상품이 등록됩니다.")
            window.location.reload()
        })
    }

    return (
      <div className="flex-col gap-8">
          <div className="flex">
              <div className="flex-1">
                  <TextInput placeholder="url을 넣어주세요" onChange={(e) => setUrl(e.target.value)} />
              </div>
              <Button variants="solid" value="AI 자동 등록" onClick={() => registration()} />
          </div>
          <Table headers={[
              { name: "id", width: "80px" }, { name: "상품명" }, { name: "url" }, { name: "생성 날짜" }
          ]} data={products}
                 pagination={{
                     rowSize: 7,
                     totalSize: products.length
                 }}
          />
      </div>
    );
};

export default ProductList;
