import Layout from "../components/layout/Layout";
import ProductDetail from "../components/page/ProductDetail";

const ProductDetailPage = () => {
  return (
    <Layout>
      <ProductDetail />
    </Layout>
  );
};
export default ProductDetailPage;
