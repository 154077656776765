import React, {useRef, useState } from "react";
import {Button} from "omoplay";

const MiniCarouselTypeContent = ({ data }) => {
    const scrollRef = useRef(null);
    const [isDrag, setIsDrag] = useState(false);
    const [startX, setStartX] = useState(0);

    const carousels = data.carousels ?? []

    const onDragStart = (e) => {
        e.preventDefault();
        if (scrollRef.current) {
            setIsDrag(true);
            setStartX(e.pageX + scrollRef.current.scrollLeft);
        }
    };

    const onDragEnd = () => {
        setIsDrag(false);
    };

    const onDragMove = (e) => {
        if (isDrag && scrollRef.current) {
            scrollRef.current.scrollLeft = startX - e.pageX;
        }
    };

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    overflowX: "auto",
                    marginRight: "40px",
                    gap: 4,
                    marginLeft: "-40px",
                }}
                className="hide-scrollbar"
                onMouseDown={onDragStart}
                onMouseMove={onDragMove}
                onMouseUp={onDragEnd}
                onMouseLeave={onDragEnd}
                ref={scrollRef}
            >
                <div style={{ width: "36px", flex: "0 0 auto" }}></div>
                {carousels
                    .filter((carousel) => carousel.attachment?.image != null)
                    .map((carousel, index) => {
                        const attachment = carousel.attachment ?? {}

                        return (
                            <div
                                className="message-content flex-col"
                                style={{maxWidth: "210px"}}
                                key={"preview-carousel-item" + index}
                            >
                                <div className="default-message-content flex-1" style={{minHeight: "150px"}}>
                                    <div style={{ fontSize: "14px", fontWeight: "600" }}>{carousel.header}</div>
                                    <div style={{ borderWidth: "0px 0px thin", borderStyle: "solid", borderColor: "rgba(0, 0, 0, 0.12)", margin: "8px 0px 16px" }} />
                                    <div style={{ whiteSpace: "break-spaces", fontSize:"12px" }}>
                                        {carousel.message}
                                    </div>
                                </div>
                                <div className="flex" style={{padding: "8px", gap: "5px"}}>
                                    {attachment.buttons?.filter((button) => button.type === "WL" || button.type === "COUPON")
                                        .map((button) => {
                                            return (
                                                <Button
                                                    style={{flex: "1", fontSize: "12px"}}
                                                    variants="grey"
                                                    onClick={() => window.open(button.urlMobile)}
                                                    value={button.name || "메시지 버튼"}
                                                />
                                            );
                                        })}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default MiniCarouselTypeContent;
