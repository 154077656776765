import {Box} from "@mui/material";


const TypeSelectionForm = ({types, selectedType, disabled, onChangeType}) => {

    return (
        <div className="grid grid-cols-2 gap-4 text-sm">
            {types.map((type, index) => (
                <div
                    key={"message-type" + index}
                    className={
                        `flex border rounded-lg cursor-pointer ${disabled ? 'disabled' : ''}` +
                        (type.key === selectedType
                            ? "border-[#4046e3] bg-indigo-50"
                            : "border-[#dbdbea]")
                    }
                    onClick={() => {
                        if (!disabled) {
                            onChangeType(type.key)
                        }
                    }}
                >
                    <Box className="w-[240px] bg-[#eaecef] min-w-[240px] rounded-l-lg">
                        <Box className="w-full ft-selection-preview-box">
                            <Box className={type.imageClass}></Box>
                        </Box>
                    </Box>
                    <div className="flex-col justify-center p-8 text-gray-600 text-xs">
                        <h5 className="font-medium text-sm">{type.title}</h5>
                        <p className="mt-2">{type.description}</p>
                        <button className="text-indigo-600 w-fit mt-2">
                            작성하기 >
                        </button>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default TypeSelectionForm