import {useSearchParams} from "react-router-dom";
import {registrationCardBill} from "../api/cash/bill.api";

const PaymentBilling = () => {
  const [query] = useSearchParams()

  registrationCardBill(query.get("authKey")).then(({data}) => {
    alert("카드가 등록 되었습니다.")
    window.location.href = "/payments"
  })

  return (<></>)
};
export default PaymentBilling;
