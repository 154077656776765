const InputGroup = ({label, description, className = "",children}) => {
  return (
    <div className={"flex-col gap-2 " + className}>
      <div>
        <label className="text-md font-bold text-gray-900 relative">
          {label}
        </label>
        <p className="text-gray-500 text-sm mb-2">{description}</p>
      </div>
      {children}
    </div>
  );
};

export default InputGroup;
