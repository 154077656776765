import { useState } from "react";
import Accordion from "../../Accordion";
import NormalButton from "../../NormalButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import MessageWrapPreview from "../../volatility-message/content/preview/MessageWrapPreview";
import { MESSAGE_TYPES } from "../../../constants/menus";
import { getContentRequired } from "../../../api/message/volatility-message.api";

export const AutomateMessageConfirm = ({
  campaignName,
  data,
  messageType,
  onClick,
}) => {
  const [open, setOpen] = useState("condition");

  return (
    <div className="border rounded-lg bg-white w-[1032px] p-8 mb-20">
      <div className="flex-col">
        <div className="py-2 border-b border-gray-300">
          <label className="text-lg font-semibold text-gray-900 relative">
            최종 확인!
          </label>
          <p className="text-gray-500 text-sm mb-2">
            마지막으로 보내기 전 설정하신 내용에 잘못된 내용은 없는지 확인해 보세요.
          </p>
        </div>
        <ul className="flex-col gap-4 mt-4">
          <li>
            <Accordion
              title={"기본 설정"}
              isOpen={open === "default"}
              isError={campaignName == null || campaignName.length === 0}
              errorText={"* 필수 설정이 완료되지 않았습니다"}
              onClick={() => setOpen(open === "default" ? null : "default")}
            >
              <ul className="flex-col divide-y divide-gray-300 text-sm p-4">
                <li className="p-4 flex items-center">
                  <label className="w-[120px] font-semibold">캠페인명</label>
                  <p>{campaignName}</p>
                </li>
                <li className="p-4 flex items-center">
                  <label className="w-[120px] font-semibold">메시지 타입</label>
                  <p>친구톡</p>
                </li>
                <li className="p-4 flex items-center">
                  <label className="w-[120px] font-semibold">친구톡 타입</label>
                  <p>
                    {messageType &&
                      MESSAGE_TYPES.filter(
                        (item) => item.key === messageType
                      )[0]?.title}
                  </p>
                </li>
              </ul>

              <div className="flex justify-end p-4">
                <NormalButton
                  className="py-1.5 px-4 flex gap-2 items-center text-sm"
                  onClick={() => onClick("default")}
                >
                  <FontAwesomeIcon icon={faEdit} />
                  <p className="underline">수정하기</p>
                </NormalButton>
              </div>
            </Accordion>
          </li>
          <li>
            <Accordion
              title={"콘텐츠"}
              isOpen={open === "content"}
              isError={!getContentRequired(messageType, data)}
              errorText={"* 필수 설정이 완료되지 않았습니다"}
              onClick={() => setOpen(open === "content" ? null : "content")}
            >
              <div className="flex justify-center mt-4 flex-col items-center">
                <div className="rounded bg-gray-200 py-1.5 px-8 my-2 text-sm">
                  미리보기
                </div>
                <MessageWrapPreview data={data} type={messageType} />
              </div>

              <div className="flex justify-end p-4">
                <NormalButton
                  className="py-1.5 px-4 flex gap-2 items-center text-sm"
                  onClick={() => onClick("content")}
                >
                  <FontAwesomeIcon icon={faEdit} />
                  <p className="underline">수정하기</p>
                </NormalButton>
              </div>
            </Accordion>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AutomateMessageConfirm;
