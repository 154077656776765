import {MessagePreview} from "omoplay";

const MessageWrapPreview = ({ messageData, type, fixed }) => {

  return (
      <MessagePreview messageData={messageData} type={type} fixed={fixed} />
  );
};

export default MessageWrapPreview;
