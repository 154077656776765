import {useEffect, useState} from "react";
import { loadTossPayments } from "@tosspayments/tosspayments-sdk"
import { ANONYMOUS } from "@tosspayments/tosspayments-sdk";
import {Button} from "omoplay";


export const RegularPayment = () => {
  const [payment, setPayment] = useState()

  useEffect(() => {
    loadTossPayments("test_ck_7XZYkKL4MrjDXZjvpoL30zJwlEWR").then((tossPayments) => {
      setPayment(tossPayments.payment({customerKey: ANONYMOUS}))
    })
  }, []);

  return (
    <div className="p-8 flex-col gap-4">
      <div className="p-6 bg-white border border-gray-300 w-1/2">
        <div className="flex justify-between items-center">
          <p className="text-gray-700">
            등록된 카드
          </p>
        </div>
        <div className="mt-3 mb-2">
          <p className="text-2xl text-gray-700 font-bold">
            카드 1234-1234-****-****
          </p>
        </div>
      </div>
      <div>
        <Button variants="solid" value="카드 등록" onClick={() => {
          payment.requestBillingAuth({
            method: 'CARD',
            successUrl: window.location.origin + '/payment/billing',
            failUrl: window.location.origin + '/payment/fail',
            customerEmail: 'customer123@gmail.com',
            customerName: '김토스',
          })
        }} />
      </div>
    </div>
  );
};

export default RegularPayment;
