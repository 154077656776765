import MessagePreview from "./content/preview/MessagePreview";
import {Button, Toggle} from "omoplay";
import {getVariantsAIMessage, saveVariantsAIMessage} from "../../api/ai/ai.api";
import {useEffect, useState} from "react";

const MessageSection = ({ id, title, variantsKey, onChangeVariants }) => {
    const [messageData, setMessageData] = useState(null)
    const [loading, setLoading] = useState(false)

    const type = messageData?.type

    useEffect(() => {
        getVariantsAIMessage(id, variantsKey).then(({data}) => {
            setMessageData(data)
            onChangeVariants({
                key: variantsKey,
                messageData: data
            })
            if (data == null) {
                setLoading(true)
                saveVariantsAIMessage(id, variantsKey).then(({data}) => {
                    setLoading(false)
                    setMessageData(data)
                })
            }
        })
    }, [])

    return (
        <div className="p-4" style={{ backgroundColor: "rgba(247, 247, 249, 1)" }}>
            <div className="flex-align-center justify-between p-4">
                <div className="flex-align-center gap-4">
                    <div>
                        <Toggle onToggle={flag => console.log(flag)} />
                    </div>
                    <div>{title}</div>
                </div>
                <div>
                    {
                        variantsKey !== "default" && (
                            loading ? <Button disabled={true} variants="solid" value="AI 생성중입니다." />
                                : <Button variants="solid" value="AI 재생성" onClick={() => {
                                    setLoading(true)
                                    saveVariantsAIMessage(id, variantsKey).then(({data}) => {
                                        setLoading(false)
                                        setMessageData(data)
                                    })
                                }} />
                        )
                    }
                </div>
            </div>
            <div className="border-b"></div>
            <div className="p-4">
                <MessagePreview messageData={messageData} type={type} />
            </div>
        </div>
    );
};

export default MessageSection