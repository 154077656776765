import MiniDefaultTypeContent from "./MiniDefaultTypeContent";
import MiniWideTypeContent from "./MiniWideTypeContent";
import MiniWideListTypeContent from "./MiniWideListTypeContent";
import MiniCarouselTypeContent from "./MiniCarouselTypeContent";

const MessagePreview = ({ messageData, type }) => {
  const renderForType = () => {
    if (type === "FI" || type === "FT") {
      return <MiniDefaultTypeContent data={messageData} />
    } else if (type === "FW") {
      return <MiniWideTypeContent data={messageData} />
    } else if (type === "FL") {
      return <MiniWideListTypeContent data={messageData} />
    } else if (type === "FC") {
      return <MiniCarouselTypeContent data={messageData} />
    }
  };

  return (renderForType());
};

export default MessagePreview;
